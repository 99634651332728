import React, { useEffect, useState, useRef } from 'react';
import { ColorRing } from  'react-loader-spinner'
function SpinnerLoader() {
  return (
    <>
    
    {/* <div className='loader'>
      <ColorRing
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} 
      />
    </div> */}





     <>
      <div className='loadergif' width="100%">
      <img
              src="/img/load.gif" 
              alt="Loading..."
          
              width="50"
            />
        </div>
    </> 
     
    </>
  );
}
export default SpinnerLoader;
