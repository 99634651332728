import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from "swiper/modules";
import Skeleton from 'react-loading-skeleton'
function FeaturedCategories() {
  const didMountRef = useRef(true);
  const [resCategoryData, setResCategoryData] = useState([]);
  const [CategoryImagePath, setCategoryImagePath] = useState('');
  
  const catCarouselOptions = {
    loop: false,
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 7.5,
      },
      600: {
        slidesPerView: 3,
      },
      1000: {
        slidesPerView: 7.1,
      },
    },
  };
  useEffect(() => {
    if(didMountRef.current){
        getCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getCategoryData = () => {
    ApiService.fetchData("featured-category").then((res) => {
      if (res.status == "success") {
        setResCategoryData(res.resCategory);
        setCategoryImagePath(res.category_image_path);
      }
    });
  };

  return (
    <> 
    <BrowserView>
    {resCategoryData.length > 0 && (
      <section className="sec-pad pb-0">
          <div className="container">
         
          <Swiper 
          spaceBetween={15}
          navigation={false}
          loop={false}
          pagination={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 5.5,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 8.1,
            },
          }}
          >
              {resCategoryData.map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                  <a href={"/collection/category/"+value.cat_slug} className="catbox item" key={index}>
                    <div className="catbox-media">
                      <img
                        src={
                          value.cat_image != null
                            ? CategoryImagePath + value.cat_image
                            : constant.DEFAULT_IMAGE
                        }
                        alt={value.cat_name}
                      />
                    </div>
                    <div className="catbox-title">{value.cat_name}</div>
                  </a>
                  </SwiperSlide>
                );
              })}
      </Swiper>
          </div>
        </section>
)}
    </BrowserView>
    <MobileView>
    {resCategoryData.length > 0 && (
      <section className="sec-pad pt-2 pb-2 mobile-cat">
        
         
          <Swiper 
          spaceBetween={10}
          navigation={false}
          loop={false}
          pagination={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 6,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 7.1,
            },
          }}
          >
              {resCategoryData.map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                  <a href={"/collection/category/"+value.cat_slug} className="catbox item" key={index}>
                    <div className="catbox-media">
                      <img
                        src={
                          value.cat_image != null
                            ? CategoryImagePath + value.cat_image
                            : constant.DEFAULT_IMAGE
                        }
                        alt={value.cat_name}
                      />
                    </div>
                    <div className="catbox-title">{value.cat_name}</div>
                  </a>
                  </SwiperSlide>
                );
              })}
      </Swiper>
     
        </section>
)}
    </MobileView>
    
    </>
  );
}
export default FeaturedCategories;
