import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import { ApiService } from "../../Components/Services/apiservices";
import SpinnerLoader from '../../Components/Elements/spinner_loader';
import Alert from 'react-bootstrap/Alert';
function LoginModal({showmodal,onChildData}) {
  const didMountRef = useRef(true);

  const [show, setShow] = useState(showmodal);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [token, setToken] = useState('');
  const [showSignup, setshowSignup] = useState(false);
  const [showSignin, setshowSignin] = useState(true);
  const [showSigninOtp, setshowSigninOtp] = useState(false);
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const [userLoginDetails, setUserLoginDetails] = useState({ 
    user_email: "",
    user_password: "",
    user_otp: "",
  }); 

  const [userRegDetails, setUserRegDetails] = useState({ 
    user_fname: "",
    user_email: "",
    user_mobile: "",
    user_password: ""
  }); 
  
  useEffect(() => {
    if (didMountRef.current) { 
      getBanner()
    }
    didMountRef.current = false;
  }, []); 

  

  const getBanner = () => { 
    setspinnerLoading(true)
    ApiService.fetchData('get-banner').then((res) => {
      if (res.status === "success") { 
        setBanner(res.banner); 
        setspinnerLoading(false)
      } 
    });
  }
 
  const handleClose = () =>{
    setShow(false)
    onChildData(false)
    setSuccessMessage("");
    setUserLoginDetails({user_email:'',user_password: "",user_otp: "",})
    setshowSignin(true);
  }  

  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setUserLoginDetails((prevState) => ({
      ...prevState,
      [name]: value,
    })); 

  };  

  const goBack = () => { 
    setshowSignin(true);
    setshowSignup(false);
    setshowSigninOtp(false);
  }

  const userLoginProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("loginRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
    
        myElements[i].style.border = '';
      }
    }
  
    if (counter === 0) {
      setErrorMessage("");
      if (!validNumber.test(userLoginDetails.user_email)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }   
      setspinnerLoading(true)
      ApiService.postData('user-login-process',userLoginDetails).then((res) => {
        if (res.status === "success") {
            setToken(res.user_token);
            setshowSignin(false);
            setshowSignup(false);
            setshowSigninOtp(true); 
            setspinnerLoading(false); 
        }else{
          setErrorMessage(res.message);
          setspinnerLoading(false);
        }
      });
    } 
  };

  const userLoginOtpProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("loginOTPRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
    
        myElements[i].style.border = '';
      }
    }
  
    if (counter === 0) {
      setErrorMessage("");
      setspinnerLoading(true)
      const dataString = {
        user_otp: userLoginDetails.user_otp,
        user_token: token,
      };
      ApiService.postData('user-login-otp-process',dataString).then((res) => {
        if (res.status === "success") {
          if (res.message === 'new_register') {
            setshowSignin(false);
            setshowSignup(true);
            setshowSigninOtp(false);
            setspinnerLoading(false)
          } else { 
             localStorage.setItem("USER_TOKEN",res.user_token)
             window.location.reload();
          }
          
        }else{
          setErrorMessage(res.message)
          setspinnerLoading(false)
        }
      });
    } 
  }; 
  
  const onTodoRegChange = (e) => {
    const { name, value } = e.target;
    setUserRegDetails((prevState) => ({
      ...prevState,
      [name]: value,
    })); 
  };

  const userRegisterProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("registerRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (userRegDetails.user_fname === '') {
        setErrorMessage("Please enter Full Name");
        return false;
      }else if (userRegDetails.user_email === '') {
        setErrorMessage("Please enter Email Id");
        return false;
      }else if (!validEmail.test(userRegDetails.user_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      } 
      setspinnerLoading(true)
      const dataString={
        "user_fname":userRegDetails.user_fname,
        "user_email":userRegDetails.user_email,
        "user_token":token,
      }
      ApiService.postData('user-register-process', dataString).then((res) => {
        if (res.status === "success") {
          localStorage.setItem("USER_TOKEN",token)
          setSuccessMessage(res.message)
          setspinnerLoading(false)
          window.location.reload();
        }else{
          setErrorMessage(res.message)
          setspinnerLoading(false)
        }
      });
    }
  };

  return (
    <> 
      <Modal show={show} onHide={handleClose} className="lrpop">
        {spinnerLoading && (<SpinnerLoader />)}  
        <button onClick={handleClose} className="pop-close"><i className="d-icon-times"></i></button>
        <div className="lrpopsection">
          <div className="lrpopsection-left"><img src={banner} /></div>
          <div className="lrpopsection-right">
        {showSignin && (
          <>
           <div className="mb-3">
           <h5 className="tx-theme">Login with Vastravihar.com</h5>
              <p className="tx-color-02">For Better Experience, Order tracking & Regular updates</p>
              </div>
            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
            <div className="form-group country-input mb-3">
              <input
                type="number"
                name="user_email"
                className="form-control loginRequired"
                value={userLoginDetails.user_email}
                onChange={(e) => onTodoChange(e)}
                placeholder="Enter Mobile Number"
              />
              <span className="country-code">+91</span>
            </div> 
            <div className="d-grid mb-4">
              <Button className="btn-primary btn01" onClick={userLoginProcess}>Continue</Button>
            </div> 
            <p className="text-center tx-12 tx-color-03">By continuing I agree with the <a href="/privacy-policy" className="tx-theme">Privacy Policy</a> and <a href="/terms-of-service" className="tx-theme">Terms &amp; Conditions</a></p>
          </>
          )}
          {showSigninOtp && (
          <>
            <div className="mb-3">
              <h5 className="tx-theme">Verify Mobile number</h5>
              <p className="tx-color-02">
                Otp has been sent to your registered mobile number {userLoginDetails.user_email} <span id="mobileData" className="tx-theme" onClick={(e)=>goBack()}>Change</span> 
              </p>
            </div>
            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
            <div className="form-group mb-3">
              <input
                type="number"
                name="user_otp"
                className="form-control loginOTPRequired"
                value={userLoginDetails.user_otp}
                onChange={(e) => onTodoChange(e)}
                placeholder="Enter OTP"
              />
            </div> 
            <div className="d-grid mb-4">
              <Button className="btn-primary btn01" onClick={userLoginOtpProcess}>Verify</Button>
            </div> 
          </>
          )}
          {showSignup && (
          <>
            <div className="text-center mb-3">
              <h5 className="tx-theme">Complete Your Registration</h5>
              <p className="tx-color-02">
                Complete your details and fill the form below
              </p>
            </div>
            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
            <div className="form-group mb-3">
              <input
                type="text"
                name="user_fname"
                className="form-control registerRequired"
                value={userRegDetails.user_fname}
                onChange={(e) => onTodoRegChange(e)}
                placeholder="Full Name"
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="text"
                name="user_email"
                className="form-control registerRequired"
                value={userRegDetails.user_email}
                onChange={(e) => onTodoRegChange(e)}
                placeholder="Email Address"
              />
            </div>   
            <div className="d-grid mb-4">
              <Button className="btn-primary btn01" onClick={userRegisterProcess}>Register</Button>
            </div> 
          </>
          )} 
          </div>
        </div>


        
      </Modal>
    </>
  );
}
export default LoginModal;
