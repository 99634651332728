import React, { useEffect, useRef, useState } from "react";

function BlogsDetailsSidebar() {

  useEffect(() => {

  }, [])
  return (
    <>
    <div>
        <h3>Categories</h3>

       
      </div>
    </>
  )
}
export default BlogsDetailsSidebar