import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant"; 
// import Testimonials from "../../Components/Elements/testimonials";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import GridBannerSecond from "../../Components/Elements/grid_banner_second";
import FeaturedVideoProducts from "../../Components/Elements/featured_video_products";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Helmet } from "react-helmet";
import SecondCategoryWiseProducts from "../../Components/Elements/second_category_wise_product";
import GridBannerThird from "../../Components/Elements/grid_banner_third";
function Home() { 
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true); 
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [pageData , setPageData] = useState("")
  const [sectionData , setSectionData] = useState([])
  useEffect(() => {
    if(didMountRef.current){
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 1000);
      getDashboardData();
      const getPageData = {
        slug: "home",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
          setPageData(res.data)
          
        } else {
          
        }
      });
    }
    didMountRef.current = false;
  }, []);
  const getDashboardData = () => { 
      ApiService.fetchData("dashboard").then((res) => {
        if (res.status == "success") {
          setSectionData(res.resAppearanceHomeData)
        }  
      });
  };

  const handleCartCount = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
  };
  return (
    <>
      <Helmet>
      <title>{pageData.page_meta_title}</title>
      <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :"99pandit.com"} />
      {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content={pageData.page_meta_title} />
      <meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content= {pageData.page_meta_desc != null ?pageData.page_meta_desc:"99pandit.com"} />
      <meta name="twitter:title" content={pageData.page_meta_title} />
      <meta name="twitter:description" content={pageData.page_meta_desc != null ?pageData.page_meta_desc:"99pandit.com"} />
      <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
      <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <BrowserView>
        {spinnerLoading && <SpinnerLoader />}
        <Header cartCount={cartCount}/>
        <HomeTopBanner />   
        {sectionData.length > 0 && (
          sectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced } key={indexSection}>
              <div className="container">
                <div className="row g-3">
                  {valueSection.has_many_home_details.length > 0 &&
                    valueSection.has_many_home_details.map((valueRow, indexRow) => {
                      let dataObj;
                      if (valueRow.home_type === 1) {
                        if (valueRow.home_layout_for_type === 1) {
                          dataObj = {
                            'id': valueRow.home_cat_id,
                            'home_product_name': valueRow.home_product_name,
                            'home_column': valueRow.home_column,
                            'home_display_type': valueRow.home_display_type,
                            'home_no_items': valueRow.home_no_items,
                            'home_col_grid': 'cols-xl-',
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <CategoryWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          );
                        } else {
                          dataObj = {
                            'id': valueRow.home_tag_id,
                            'home_product_name': valueRow.home_product_name,
                            'home_column': valueRow.home_column,
                            'home_display_type': valueRow.home_display_type,
                            'home_no_items': valueRow.home_no_items,
                            'home_col_grid': 'cols-xl-',
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <TagWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          );
                        }
                      } else if (valueRow.home_type === 2){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 3){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 4){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 5){
                          dataObj = {
                            'home_image_link': valueRow.home_image_link,
                            'home_image_url': valueRow.home_image_url,
                            'home_image': valueRow.home_image,
                            'home_res_image_link': valueRow.home_res_image_link,
                            'home_res_image_url': valueRow.home_res_image_url,
                            'home_res_image': valueRow.home_res_image,
                            'spinnerLoading': spinnerLoading,
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <GridBannerFirst
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          ); 
                      } 
                    })}
                </div>
              </div>
            </section>
          ))
        )} 
        <Footer />
      </BrowserView>
      <MobileView>
        {spinnerLoading && <SpinnerLoader />}
        <Header cartCount={cartCount}/>
        <FeaturedCategories />
        <HomeTopBanner /> 
        {sectionData.length > 0 && (
          sectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced } key={indexSection}>
              <div className="container">
                <div className="row g-2">
                  {valueSection.has_many_home_details.length > 0 &&
                    valueSection.has_many_home_details.map((valueRow, indexRow) => {
                      let dataObj;
                      if (valueRow.home_type === 1) {
                        if (valueRow.home_layout_for_type === 1) {
                          dataObj = {
                            'id': valueRow.home_res_cat_id,
                            'home_product_name': valueRow.home_res_product_name,
                            'home_column': valueRow.home_res_column,
                            'home_display_type': valueRow.home_res_display_type,
                            'home_no_items': valueRow.home_res_no_items,
                            'home_col_grid': 'cols-',
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <CategoryWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          );
                        } else {
                          dataObj = {
                            'id': valueRow.home_res_tag_id,
                            'home_product_name': valueRow.home_res_product_name,
                            'home_column': valueRow.home_res_column,
                            'home_display_type': valueRow.home_res_display_type,
                            'home_no_items': valueRow.home_res_no_items,
                            'home_col_grid': 'cols-',
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <TagWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          );
                        }
                      } else if (valueRow.home_type === 2){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 3){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 4){
                        return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      }else if (valueRow.home_type === 5){
                          dataObj = {
                            'home_image_link': valueRow.home_image_link,
                            'home_image_url': valueRow.home_image_url,
                            'home_image': valueRow.home_image,
                            'home_res_image_link': valueRow.home_res_image_link,
                            'home_res_image_url': valueRow.home_res_image_url,
                            'home_res_image': valueRow.home_res_image,
                            'spinnerLoading': spinnerLoading,
                          };
                          return (
                            <div className={"col-lg-" + valueRow.home_layout_type + " " + valueSection.home_grid} key={indexRow}>
                              <GridBannerFirst
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                            </div>
                          ); 
                      } 
                    })}
                </div>
              </div>
            </section>
          ))
        )}           
    <Footer />
      </MobileView>
    </>
  );
}
export default Home;
