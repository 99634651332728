// const API_URL = "https://balhath.com/csadmin/api/"
// const BASE_URL =   "https://balhath.com/" 
const API_URL = "https://vastravihar.com/admin/backendapi/api"



const BASE_URL =   "https://vastravihar.com"    

const FRONT_URL =   "http://192.168.1.2:3001/"    
const DEFAULT_IMAGE = "/img/defaultimage.png";
const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE };